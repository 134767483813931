import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';
import styles from './DialogLogin.module.scss';
import { DialogHeader } from '../../molecules/DialogHeader';
import { DialogLoginBody } from '../../molecules/DialogLoginBody';
import { useDialogLogin } from './DialogLogin.hooks';
import { DialogFooter } from '../../molecules/DialogFooter';
import { DialogLoginProps } from './DialogLogin.types';
import { RootState } from '../../../store';

export const DialogLogin = ({ onLogin }: DialogLoginProps): JSX.Element | null => {
  const { loading, statusCode } = useSelector((state: RootState) => state.authFirebase);
  const { root, isOpen, register, handleSubmit, onSubmit, errors, loginErrorMessage, handleAuthErrorStatus } =
    useDialogLogin(onLogin, statusCode);

  if (!root || !isOpen) return null;

  return ReactDom.createPortal(
    <div className={styles.container}>
      <form className={styles.dialogLogin} onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader text="ログイン" isShowButtonClose={false} />
        <DialogLoginBody
          emailRegister={register('email')}
          passwordRegister={register('password')}
          errors={errors}
          loginErrorMessage={loginErrorMessage}
          handleAuthErrorStatus={handleAuthErrorStatus}
        />
        <DialogFooter
          primaryButtonDisabled={loading}
          primaryButtonText="ログイン"
          onClickPrimaryButton={handleSubmit(onSubmit)}
        />
      </form>
    </div>,
    root,
  );
};
