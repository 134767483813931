import { DynamicBackgroundProps } from './DynamicBackgroundFixed.types';
import styles from './DynamicBackgroundFixed.module.scss';
import { ENTRANCE_MOVIE_URL, ENTRANCE_MOVIE_TITLE } from '../../../constants/MovieUrl';

export const DynamicBackgroundFixed = ({ children }: DynamicBackgroundProps): JSX.Element => (
  <div className={styles.dynamicBackground}>
    <div className={styles.videoContainer}>
      <iframe
        data-chromatic="ignore"
        className={styles.videoIframe}
        src={ENTRANCE_MOVIE_URL}
        allow="autoplay; fullscreen; picture-in-picture"
        title={ENTRANCE_MOVIE_TITLE}
      />
    </div>
    <div className={styles.backgroundImage}>{children}</div>
  </div>
);
