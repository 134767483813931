import styles from './InputLabelWithTextForm.module.scss';
import { InputLabelWithTextFormProps } from './InputLabelWithTextForm.types';
import { TextField } from '../../atoms/TextField';

export const InputLabelWithTextForm = ({
  icon,
  label,
  ariaLabel,
  errored,
  placeholder,
  textFieldType,
  disabled,
  register,
  onFocus,
}: InputLabelWithTextFormProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.iconAndLabel}>
      {icon}
      <span>{label}</span>
    </div>
    <TextField
      ariaLabel={ariaLabel}
      textFieldType={textFieldType}
      errored={errored}
      placeholder={placeholder}
      disabled={disabled}
      register={register}
      onFocus={onFocus}
    />
  </div>
);
