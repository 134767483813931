import styles from './DialogFooter.module.scss';
import { DialogFooterProps } from './DialogFooter.types';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton';

export const DialogFooter = ({
  primaryButtonText,
  onClickPrimaryButton,
  primaryButtonDisabled,
  secondaryButtonText,
  onClickSecondaryButton,
  secondaryButtonDisabled,
}: DialogFooterProps): JSX.Element => (
  <div className={styles.container}>
    {secondaryButtonText && (
      <SecondaryButton text={secondaryButtonText} onClick={onClickSecondaryButton} disabled={secondaryButtonDisabled} />
    )}
    <PrimaryButton text={primaryButtonText} onClick={onClickPrimaryButton} disabled={primaryButtonDisabled} />
  </div>
);
