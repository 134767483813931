import styles from './ErrorMessage.module.scss';
import { Warning } from '../../../assets/icons';
import { ErrorMessageProps } from './ErrorMessage.types';

export const ErrorMessage = ({ message }: ErrorMessageProps): JSX.Element => (
  <div className={styles.errorMessage}>
    <div className={styles.errorIcon}>
      <Warning />
    </div>
    <span className={styles.errorLabel}>{message}</span>
  </div>
);
