import styles from './DialogLoginBody.module.scss';
import { InputLabelWithTextForm } from '../InputLabelWithTextForm/InputLabelWithTextForm.component';
import { Mail, VpnKey } from '../../../assets/icons';
import { DialogLoginBodyProps } from './DialogLoginBody.types';
import { ErrorMessage } from '../../atoms/ErrorMessage';

export const DialogLoginBody = ({
  errors,
  passwordRegister,
  emailRegister,
  loginErrorMessage,
  handleAuthErrorStatus,
}: DialogLoginBodyProps) => (
  <div className={styles.container}>
    <InputLabelWithTextForm
      register={emailRegister}
      textFieldType="text"
      icon={<Mail />}
      label="メールアドレス"
      placeholder="メールアドレスを入力してください"
      errored={!!errors?.email}
      onFocus={handleAuthErrorStatus}
    />
    <InputLabelWithTextForm
      register={passwordRegister}
      textFieldType="password"
      icon={<VpnKey />}
      label="パスワード"
      placeholder="パスワードを入力してください"
      errored={!!errors?.password}
      onFocus={handleAuthErrorStatus}
    />
    {loginErrorMessage && <ErrorMessage message={loginErrorMessage} />}
  </div>
);
