import styles from './PrimaryButton.module.scss';
import { PrimaryButtonProps } from './PrimaryButton.types';

export const PrimaryButton = ({ disabled = false, onClick, text }: PrimaryButtonProps): JSX.Element => {
  const status = disabled ? 'disabled' : '';
  return (
    <button
      type="button"
      disabled={disabled}
      className={styles.container}
      onClick={onClick}
      data-testid={`primaryButton-${text}`}
    >
      <span className={styles[status]}>{text}</span>
    </button>
  );
};
