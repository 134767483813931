import styles from './DialogHeader.module.scss';
import { DialogHeaderProps } from './DialogHeader.types';
import { SmallButtonIconOnly } from '../../atoms/SmallButtonIconOnly';
import { Close } from '../../../assets/icons';

export const DialogHeader = ({
  text,
  onClose,
  buttonCloseDisabled = false,
  isShowButtonClose = true,
  isFixedRoom,
  isTextLeftAligned = false,
}: DialogHeaderProps): JSX.Element => (
  <div className={styles.container} data-is-text-left-aligned={isTextLeftAligned}>
    <span>{text}</span>
    {isShowButtonClose && !isFixedRoom && (
      <div className={styles.buttonClose}>
        <SmallButtonIconOnly disabled={buttonCloseDisabled} icon={<Close />} ariaLabel="閉じる" onClick={onClose} />
      </div>
    )}
  </div>
);
