import { useEffect } from 'react';
import { videoCacheMessage } from './useVideoCacheWorker.utils';

export const useVideoCacheWorker = () => {
  const videoCacheServiceWorker = navigator.serviceWorker;

  // Service Worker に動画をキャッシュするようメッセージを送る
  useEffect(() => {
    videoCacheServiceWorker.controller?.postMessage(videoCacheMessage);
    // 初回アクセス時は Service Worker の準備ができたらメッセージを送る
    // https://developer.mozilla.org/ja/docs/Web/API/ServiceWorkerContainer/controllerchange_event
    videoCacheServiceWorker.oncontrollerchange = () => {
      videoCacheServiceWorker.controller?.postMessage(videoCacheMessage);
    };
  }, [videoCacheServiceWorker]);
};
