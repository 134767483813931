import { DynamicBackgroundFixed } from '../../atoms/DynamicBackgroundFixed';
import { DialogLogin } from '../../organisms/DialogLogin';
import { useLogin } from './Login.hooks';

export const Login = (): JSX.Element => {
  const { handleSignIn } = useLogin();
  return (
    <DynamicBackgroundFixed>
      <DialogLogin onLogin={handleSignIn} />
    </DynamicBackgroundFixed>
  );
};
