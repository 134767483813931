import styles from './TextField.module.scss';
import { TextFieldProps } from './TextField.types';
import { useTextField } from './TextField.hooks';
import { RemoveRedEye, VisibilityOff } from '../../../assets/icons';

export const TextField = ({
  ariaLabel,
  placeholder,
  textFieldType = 'text',
  errored = false,
  disabled = false,
  register,
  onFocus,
}: TextFieldProps): JSX.Element => {
  const { toggleVisiblePassword, isVisible, typeInput } = useTextField(textFieldType);

  return (
    <div className={styles.container}>
      <input
        className={`${styles.textField} ${errored ? styles.error : ''} ${
          typeInput === 'password' ? styles.dotCharacter : ''
        }`}
        aria-label={ariaLabel}
        placeholder={placeholder}
        type={typeInput}
        disabled={disabled}
        {...register}
        onFocus={onFocus}
      />
      {textFieldType === 'password' && (
        <button type="button" className={styles.iconEye} onClick={toggleVisiblePassword}>
          {!isVisible ? <RemoveRedEye /> : <VisibilityOff />}
        </button>
      )}
    </div>
  );
};
