import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import { captureException } from '@sentry/react';
import { useRouter } from 'next/router';
import { IdTokenResult, getAuth } from 'firebase/auth';
import type { RootState, AppDispatch } from '../../../store';
import { OnLogin } from '../../organisms/DialogLogin/DialogLogin.types';
import { openLogin } from '../../../store/features/dialog';
import { useVideoCacheWorker } from './hooks';
import { signInWithEmailAndPasswordThunk, throwErrorAuthIdentity } from '../../../store/features/authFirebase';

// eslint-disable-next-line max-lines-per-function
export const useLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: RootState) => state.authFirebase);
  const router = useRouter();
  const routerRef = useRef(router);

  // NOTE: シナリオUUIDをパラメータから取得するのは仮実装
  const scenarioUuidResult = useMemo(
    () =>
      router.query && 'scenario_uuid' in router.query && typeof router.query.scenario_uuid === 'string'
        ? router.query.scenario_uuid
        : undefined,
    [router.query],
  );

  const handleSignIn: OnLogin = (email, password) => {
    dispatch(signInWithEmailAndPasswordThunk({ email, password }));
  };

  useVideoCacheWorker();

  useEffect(() => {
    if (authState?.identity === undefined || authState?.identity === null) {
      dispatch(openLogin());

      return;
    }
    if (authState.loading === false && authState.error.length === 0) {
      (async () => {
        const firebaseAuth = getAuth();

        if (!firebaseAuth.currentUser) throw new Error('user not found');
        const result: IdTokenResult = await firebaseAuth.currentUser.getIdTokenResult();
        const url = scenarioUuidResult ? `/avacom?scenario_uuid=${scenarioUuidResult}` : '/avacom';
        // NOTE: claims.roleがない時をCUSTOMERとして画面遷移する
        if (!('role' in result.claims)) {
          // claims.roleがない場合は、/avacom画面へ遷移する
          await routerRef.current.replace(url);
          return;
        }
        if (result.claims.role === 'OPERATOR' || result.claims.role === 'MANAGER') {
          // ロールが'OPERATOR'や'MANAGER'の場合は、エラーをthrowする
          throw new Error('Invalid role');
        }
        // ロールが'OPERATOR'や'MANAGER'以外の場合は、/avacom画面へ遷移する（roleが空文字の場合がある）
        await routerRef.current.replace(url);
      })().catch((e) => {
        captureException(e);
        dispatch(throwErrorAuthIdentity({ errorCode: 401 }));
      });
    }
  }, [authState, dispatch, scenarioUuidResult]);

  return {
    handleSignIn,
  } as const;
};
