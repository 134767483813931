import { useMemo, useState } from 'react';

export const useTextField = (textFieldType: 'text' | 'password') => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisiblePassword = () => {
    setIsVisible(!isVisible);
  };

  const typeInput = useMemo(
    () => (textFieldType === 'password' && !isVisible ? 'password' : 'text'),
    [isVisible, textFieldType],
  );

  return {
    typeInput,
    isVisible,
    toggleVisiblePassword,
  };
};
