import { SmallButtonIconOnlyProps } from './SmallButtonIconOnly.types';
import styles from './SmallButtonIconOnly.module.scss';

export const SmallButtonIconOnly = ({
  icon,
  disabled = false,
  onClick,
  ariaLabel,
}: SmallButtonIconOnlyProps): JSX.Element => (
  <button
    type="button"
    disabled={disabled}
    className={styles.container}
    aria-label={ariaLabel}
    onClick={onClick}
    data-testid={`smallButtonIconOnly-${ariaLabel}`}
  >
    <div className={styles.wrapSvg}>{icon}</div>
  </button>
);
